'use client'

import { Box, ButtonPill, spacing } from '@fortum/elemental-ui'

import type { ArticleCategoryEntry } from '@/shared/contentful/types'
import { useLocale } from '@/shared/locale'
import { Link, getPathname } from '@/shared/navigation'
import { routes } from '@/shared/routes'
import { slugifyTitle } from '@/shared/sections/ArticleFrontPageSection/utils'
import type { PageCustomerType } from '@/shared/types/Common'

export type ArticleCategoriesProps = {
  categories: ArticleCategoryEntry[]
  scope: PageCustomerType
}

export const ArticleCategories = ({ categories, scope }: ArticleCategoriesProps) => {
  const locale = useLocale()
  const blogPath = getPathname({
    href: scope === 'enterprise' ? routes.ENTERPRISE_BLOG : routes.BLOG,
    locale,
  })

  return (
    <Box display="flex" flexWrap="wrap" gap={spacing.xxs} mb={spacing.s}>
      {categories.map(({ sys, ...fields }) => {
        const href = `${blogPath}/${slugifyTitle(fields.title)}/1`

        return (
          <ButtonPill
            tag={Link}
            key={sys.id}
            status="secondary"
            variant="condensed"
            size="s"
            href={href}
            data-testid="article-category-link"
          >
            {fields.title}
          </ButtonPill>
        )
      })}
    </Box>
  )
}
