'use client'

import { useTranslations } from 'next-intl'
import { Fragment } from 'react'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'
import { useFormatter } from '@/shared/locale'

export type ArticleMetadataProps = {
  /**
   * Reading time in minutes.
   */
  readingTimeMinutes: number
  /**
   * Author name.
   */
  authorName: string | undefined | null
  /**
   * Last updated date.
   */
  updated: string | undefined | null
}

export const ArticleMetadata = ({
  authorName,
  updated,
  readingTimeMinutes,
}: ArticleMetadataProps) => {
  const { colors } = useTheme()
  const formatter = useFormatter()
  const t = useTranslations('articlePage')

  const formattedReadingTime = t('readingTime', { minutes: readingTimeMinutes })

  const formattedDate = updated
    ? `${t('lastUpdated')}: ${formatter.dateTime(new Date(updated))}`
    : undefined

  const items = [formattedReadingTime, authorName, formattedDate].filter(Boolean)

  return (
    <ContentText
      tag="div"
      size="s"
      color={colors.textSecondary}
      mb={spacing.s}
      textAlign="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      gap={{ default: spacing.xxxs, s: spacing.xs }}
      style={{ whiteSpace: 'nowrap' }}
    >
      {items.map((item, index) => (
        <Fragment key={index}>
          <div data-testid="article-metadata-item">{item}</div>
          {index < items.length - 1 && <Box br="1px solid currentColor" />}
        </Fragment>
      ))}
    </ContentText>
  )
}
