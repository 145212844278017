'use client'

import type { ArticleEntry } from '@/shared/contentful/types'
import { getArticleJsonLd } from '@/shared/utils/jsonLd'

export type ArticleJsonLdProps = ArticleEntry

export const ArticleJsonLd = (article: ArticleJsonLdProps) => {
  const jsonLd = getArticleJsonLd(article)

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}
