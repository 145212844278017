'use client'

import { BLOCKS } from '@contentful/rich-text-types'

import { Box, spacing } from '@fortum/elemental-ui'

import type { RichTextDocument } from '@/shared/components/RichText'
import { ImageSizesProvider, RichText, renderRichTextHeading } from '@/shared/components/RichText'
import { getSizesForColumns } from '@/shared/utils/image'

export type ArticleContentProps = {
  content: RichTextDocument
}

export const ArticleContent = ({ content }: ArticleContentProps) => {
  return (
    <Box mb={spacing.s}>
      <ImageSizesProvider value={getSizesForColumns({ l: 8, xl: 6 })}>
        <RichText
          size="l"
          document={content}
          customOptions={{
            renderNode: {
              // Style heading levels 2–3 as levels 4–5
              [BLOCKS.HEADING_2]: renderRichTextHeading(2, 4),
              [BLOCKS.HEADING_3]: renderRichTextHeading(3, 5),
            },
          }}
        />
      </ImageSizesProvider>
    </Box>
  )
}
