'use client'

import { useTranslations } from 'next-intl'

import { ButtonPill, IconArrowUp } from '@fortum/elemental-ui'

export const ScrollToTopButton = () => {
  const t = useTranslations('scrollToTopButton')

  return (
    <ButtonPill
      status="secondary"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      width="100%"
      rightIcon={<IconArrowUp />}
    >
      {t('text')}
    </ButtonPill>
  )
}
